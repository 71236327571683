<template>
	<div class="content">
		<div class="tilte">
			<div></div>
			<span>{{ $t('mine.menuList[3]') }}</span>
		</div>
		<div class="tabeList">
			<div class="list" @click="clickTab(index)" :class="taberIndex == index ? 'active' : ''"
				v-for="(item, index) of $t('mine.orderList')" :key="index">{{ item }}</div>
		</div>
		<div class="order">
			<div class="order-list" v-for="(item, index) of orderList" :key="index">
				<div class="orderbox" @click="navTo('/mine/orderDetails', { orderId: item.id })">
					<div class="orderNumber">
						{{ $t('mine.elses.dingdanbiaohao') }}： {{ item.orderNumber }}
					</div>
					<div class="product" v-for="(items, indexs) of item.detailList" :key="indexs">
						<div class="list">
							<img class="pic" :src="items.cover" alt="">
							<div class="productDatil">
								<div class="name dis">
									<span>{{ isChinese() ? items.name : items.nameEng }}</span>
									<span>¥{{ items.price }}</span>
								</div>
								<div class="sku">
									<div><span></span> {{ isChinese() ? items.keyName : items.keyNameEng }}</div>
									<span>x{{ items.number }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="after">
					<div class="button" v-if="item.status == 0" @click="showDialog(1, item.id)">
						{{ $t('button.quxiaodingdan') }}
					</div><!--取消订单-->
					<div class="button blck" v-if="item.status == 0" @click="payOrderWeb(item)">
						{{ $t('button.quzhifu') }}
					</div><!--去支付-->
					<div class="button" v-if="item.status == 1" @click="showDialog(2, item.id)">
						{{ $t('button.tuikuan') }}
					</div><!--申请退款-->
					<div class="button" @click="navTo('/mine/logistics?orderNumber=' + item.orderNumber)" v-if="true">
						{{ $t('button.chakanwuliu') }}
					</div><!--查看物流-->
					<div class="button blck" v-if="item.status == 2" @click="showDialog(3, item.id)">{{ $t('button.shouhuo')
                    }}</div><!--确认收货-->
					<div class="button" @click="navTo('/mine/afterSale?id=' + item.id)"
						v-if="item.status == 3||item.status == 4">
						{{ $t('button.shenqingshouhuo') }}
					</div><!--申请售后-->
					<div class="button blck" @click="navTo('/mine/orderScore?id=' + item.id)" v-if="item.status == 3">
						{{ $t('button.qupingjia') }}
					</div><!--去评价-->
					<div class="button" v-if="item.status == 6" @click="showDialog(4, item.id)">
						{{ $t('button.chexiao') }}
					</div><!--撤销申请-->
					<div class="button blck" @click="navTo('/mine/lookApply?id=' + item.id)" v-if="item.status == 6">
						{{ $t('button.see') }}
					</div><!--查看申请-->
				</div>
			</div>
			<div class="empty" v-if="orderList.length == 0">
				<empty :title="isChinese()?'订单为空':'Empty order'"></empty>
			</div>
		</div>
		<div class="pagination" v-if="total!=0">
			<div class="pages">
				<div class="dis">
					<!--  -->
					<i class="el-icon-arrow-left" @click="clickLeft" style="font-size: 30px;"
						:style="{ color: pageNum == 1 ? '#999' : '#000' }"></i>
					<div>
						{{ pageNum }} / {{ Math.ceil(total / 5) }}
					</div>
					<i class="el-icon-arrow-right" @click="clickRigth" style="font-size: 30px;"
						:style="{ color: pageNum > 1 ? '#000' : '#999' }"></i>
				</div>
			</div>
		</div>
		<el-dialog
			:title="dialogType == 1 ? $t('message.quxiaotisi') : dialogType == 2 ? $t('mine.elses.tuikuanTime') : dialogType == 3 ? $t('mine.elses.querentis') : $t('button.chexiao')"
			:visible.sync="centerDialogVisible" width="20%" center top="15%">
			<div class="dialog">
				{{ dialogType == 1 ? $t('mine.elses.quxiaodingdan') : dialogType == 2 ?
                    $t('mine.elses.tuikuanContent') : dialogType == 3 ? $t('mine.elses.querenShouhuo') :
                        $t('mine.elses.chexiaoshenqing') }}
			</div>
			<span slot="footer" class="dialog-footer">
				<div @click="cancel" style="border-right:1px solid #ECECEC;">
					{{ dialogType == 2 ? $t('mine.elses.button') : $t('button.quxiao') }}
				</div>
				<div @click="confirm">{{ $t('button.queding') }}</div>

			</span>
		</el-dialog>
		<el-dialog title=" " :visible.sync="payDialogVisible" width="30%" :before-close="handleClose">
			<div class="pay">
				<div class="title">{{ $t('submitOrder.yingfujine') }}</div>
				<div class="total">¥{{ totalPic }}</div>
				<div class="type">
					<div class="wx">
						<div>
							<img :src="wchatPayPic" alt="">
						</div>
						<div>
							<img src="../../../public/imgs/mine/wxpay.png" alt="">
							{{ $t('submitOrder.wahctQce') }}
						</div>
					</div>

				</div>
				<el-button type="primary" style="padding: 15px 20px;margin: auto;"
					@click="getOrderStatus">{{isChinese() ?  '支付成功':'Payment Success'}}</el-button>
			</div>
		</el-dialog>
		<el-dialog :title="isChinese() ?'支付':'Choice of payment method'" :visible.sync="dialogVisible" width="500px"
			:before-close="handleClose" center>
			<div class="fabumainboxpay">
				<div class="fabumainboxpay-titlef">
					<p class="fabumainboxpay-p">￥{{ totalPic }} </p>
				</div>
				<!-- <div class="fabumainboxpay-pay">
                    <div class="fabumainboxpay-pay-line" @click="selcetPayType(item, index)"
                        v-for="(item, index) of payTypeList" :key="index">
                        <div class="flexbox">
                            <img :src="item.img" alt="" class="wximg">
                            <div>{{item.text}}</div>
                        </div>
                        <img :src="item.ico" class="chooseimg">
                    </div>
                </div> -->
			</div>
			<div slot="footer" class="dialog-footer-ggpay">
				<el-button @click="cancel">{{$t('button.quxiao')}}</el-button>
				<el-button type="primary" @click.stop="topay">{{$t('button.queding')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		listUserOrder,
		cancelOrder,
		receiveOrder,
		revocationOrder,
		getOrderStatus,
		getOrderNumber
	} from '@/api/main.js'
	import empty from '@/components/empty.vue'
	// import base from '@/config/env'
	export default {
		name: 'orderList',
		components: {
			empty
		},
		data() {
			return {
				centerDialogVisible: false,
				taberList: ['待支付', '代发货', '待收货', '待评价', '已完成', '已取消', '退款/售后'],
				taberIndex: 0, //0待支付，1待发货，2待收货，3待评价，4已完成，5已取消，6退款/售后申请，7审核成功，8审核失败，9已退款。默认0

				dialogType: 1, //1取消订单  2.申请退款 3.收货提示 4.撤销退款申请 
				orderList: [],
				pageNum: 1,
				total: 1,
				orderId: '', //选中订单id

				wchatPayPic: '',
				alipayPic: '',
				payDialogVisible: false,
				totalPic: 0, //当前支付商品的价格

				dialogVisible: false,
				selectPayRoder: {}, //选中的支付订单
				payTypeList: [{
						text: '微信支付',
						img: require('../../../public/imgs/mine/wxpay.png'),
						ico: require('../../../public/imgs/yes.png'),
						type: 1
					},
					{
						text: '支付宝支付',
						img: require('../../../public/imgs/mine/alipay.png'),
						ico: require('../../../public/imgs/ofOn.png'),
						type: 2
					}
				],
				payType: 1
			}
		},
		activated() {
			this.orderList = [];
			this.getlistUserOrder();
		},

		mounted() {
			const popup = document.createElement('script')
			popup.setAttribute('src', 'https://js.paystack.co/v2/inline.js')
			popup.async = true
			document.head.appendChild(popup)
		},
		methods: {
			selcetPayType(item, index) {
				this.payTypeList.forEach((e, i) => {
					if (i == index) {
						e.ico = require('../../../public/imgs/yes.png')
					} else {
						e.ico = require('../../../public/imgs/ofOn.png')
					}

				})
				this.payType = item.type
			},
			cancel() {
				this.centerDialogVisible = false
				this.dialogVisible = false
			},
			confirm() {
				if (this.dialogType == 1) { //取消订单
					cancelOrder({
						orderId: this.orderId
					}).then(res => {
						if (res.code == 0) {
							if (this.isChinese()) {
								this.$message({
									message: '取消成功',
									type: 'success'
								});
							} else {
								this.$message({
									message: 'Cancel successfully',
									type: 'success'
								});
							}
							this.centerDialogVisible = false
							this.clickTab(this.taberIndex); //刷新页面
						} else {
							this.showMessage(res)
						}
					})
				} else if (this.dialogType == 2) { //申请退款
					this.centerDialogVisible = false
					this.navTo('/mine/afterSale?id=' + this.orderId);
					// refundAfterOrder({
					//     orderId: this.orderId
					// }).then(res => {
					//     if (res.code == 0) {
					//         if (this.isChinese()) {
					//             this.$message({
					//                 message: '提交成功',
					//                 type: 'success'
					//             });
					//         } else {
					//             this.$message({
					//                 message: 'Submit successfully',
					//                 type: 'success'
					//             });
					//         }
					//         this.centerDialogVisible = false
					//         this.clickTab(this.taberIndex);
					//     } else {
					//         this.showMessage(res)
					//     }
					// })

				} else if (this.dialogType == 3) { //确认收货
					receiveOrder({
						orderId: this.orderId
					}).then(res => {
						if (res.code == 0) {
							if (this.isChinese()) {
								this.$message({
									message: '收货成功',
									type: 'success'
								});
							} else {
								this.$message({
									message: 'Goods received successfully',
									type: 'success'
								});
							}
							this.centerDialogVisible = false
							this.clickTab(this.taberIndex);
						} else {
							this.showMessage(res)
						}
					})
				} else { //撤销申请 
					revocationOrder({
						orderId: this.orderId
					}).then(res => {
						if (res.code == 0) {
							if (this.isChinese()) {
								this.$message({
									message: '撤销成功',
									type: 'success'
								});
							} else {
								this.$message({
									message: 'Undo successfully',
									type: 'success'
								});
							}
							this.centerDialogVisible = false
							this.clickTab(this.taberIndex);
						} else {
							this.showMessage(res)
						}
					})
				}
			},
			showDialog(type, id) {
				this.orderId = id;
				this.dialogType = type;
				this.centerDialogVisible = true
			},
			clickLeft() { //上一页
				if (this.pageNum <= 1) return;
				this.pageNum--;
				this.getlistUserOrder()
			},
			clickRigth() { //下一页
				if (this.pageNum >= Math.ceil(this.total / 5)) return;
				this.pageNum++;
				this.getlistUserOrder()
			},
			getlistUserOrder() { //订单列表
				listUserOrder({
					status: this.taberIndex,
					pageNum: this.pageNum,
					pageSize: 5
				}).then(res => {
					if (res.code == 0) {
						this.total = res.data.total;
						this.orderList.push(...res.data.rows);
					} else {
						this.showMessage(res)
					}
				})
			},

			clickTab(index) {
				this.taberIndex = index;
				this.orderList = [];
				this.pageNum = 1;
				this.getlistUserOrder()
			},
			navTo(path, obj) {
				this.$router.push({
					path: path,
					query: obj
				})
			},
			//验证支付成功未成功按钮
			getOrderStatus() {
				getOrderStatus({
					orderId: this.selectPayRoder.id,
				}).then(res => {
					if (res.code == 0) {
						if (res.data == 0) {
							this.$message({
								type: 'warning',
								message: this.isChinese() ? '支付未成功' : 'Payment failed'
							});
						} else {
							this.payDialogVisible = false;
							this.$message({
								type: 'success',
								message: this.isChinese() ? '支付成功' : 'Payment success'
							});
							this.taberIndex = 1;
							this.orderList = [];
							this.pageNum = 1;
							this.getlistUserOrder()
						}
					}
				})
			},
			topay() { //支付方式
				getOrderNumber({
					orderId: this.selectPayRoder.id,
				}).then(res => {
					if (res.code == 0) {
						console.log(res)
						const data = {
							email: '11@qq.com',
							amount: (this.totalPic * 100).toFixed(0),
							reference: res.data.orderNumber,
							out_trade_no: '2222',
						}
						const url = 'https://api.paystack.co/transaction/initialize'
						const headers = new Headers()
						headers.append('Authorization', 'Bearer sk_test_da0db0f457e3eec0c06f2a0fb2cb02fb47bed90b')
						fetch(url, {
								method: 'POST',
								headers: headers,
								body: JSON.stringify(data)
							})
							.then(response => response.json())
							.then(ret => {
								console.log(ret)
								const paystack = new window.PaystackPop()
								console.log(paystack)
								paystack.resumeTransaction(ret.data.access_code)
							})
							.catch(() => {
								// handle error here
							})
					} else {
						this.showMessage(res)
					}
				})
				
				// payOrderWeb({
				// 	orderId: this.selectPayRoder.id,
				// 	type: this.payType, //1微信 2.支付宝
				// }).then(res => {
				// 	if (res.code == 0) {
				// 		console.log(res)
				// 		this.dialogVisible = false
				// 		if (this.payType == 1) {
				// 			this.payDialogVisible = true;
				// 			this.wchatPayPic = base.baseURL + 'api/getQRCode?code_url=' + res.orderString;
				// 		} else {
				// 			document.write(res.orderString)
				// 		}
				// 	} else {
				// 		this.showMessage(res)
				// 	}
				// })
			},
			payOrderWeb(item) { //支付接口
				this.orderId = item.id
				this.totalPic = item.totalPrice
				this.selectPayRoder = item;
				this.dialogVisible = true
			},
			handleClose() {
				this.payDialogVisible = false;
				this.dialogVisible = false
			},
		}
	}
</script>
<style lang="scss" scoped>
	.content {
		width: 880px;

		.tilte {

			display: flex;
			align-items: center;
			padding: 9px 0px;
			padding-top: 0px;

			div {
				width: 2px;
				height: 24px;
				background: #333333;
				margin-right: 10px;
			}

			span {
				font-size: 20px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
			}
		}

		.tabeList {
			display: flex;
			align-items: center;
			padding: 30px 30px 0px 12px;
			border-bottom: 1px solid #ECECEC;

			div {
				// width: 100px;
				// border:1px solid red;
				text-align: center;
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				line-height: 22px;
				cursor: pointer;
				margin-right: 10px;
				padding: 7px 10px;
				text-align: center;
				//
			}

			.active {
				color: #E92D2D;
				position: relative;
			}

			.active:after {
				position: absolute;
				left: 50%;
				margin-left: -25px;
				text-align: center;
				content: '';
				display: block;
				width: 50px;
				height: 2px;
				background: #E92D2D;
				margin-top: 5px;
			}
		}

		.order {
			min-height: 500px;
		}

		.order-list {
			padding-top: 20px;

			.orderbox {
				padding: 16px;
				padding-bottom: 0px;
				width: 880px;
				background: #F9F9F9;

				.orderNumber {
					font-size: 13px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					padding: 10px 0px;
					padding-top: 0px;
					border-bottom: 1px solid #F1F1F1;
				}

				.product {
					.list {
						padding: 20px 0px;
						display: flex;
						justify-content: space-between;

						.pic {
							width: 120px;
							height: 120px;
							background: #ECE8F1;
						}

						.productDatil {
							width: 700px;

							.dis {
								display: flex;
								justify-content: space-between;
								align-items: center;
							}

							.name {
								span {
									font-size: 18px;
									font-family: PingFangSC-Regular, PingFang SC;
									font-weight: 400;
									color: #333333;
								}

								i {
									font-size: 25px;
								}
							}

							.sku {
								padding: 22px 0px;
								font-size: 16px;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
								color: #333333;
							}

							.del,
							.sku {
								display: flex;
								justify-content: space-between;
								align-items: center;

								span {
									font-size: 16px;
									font-family: PingFangSC-Regular, PingFang SC;
									font-weight: 400;
									color: #AAAAAA;
								}
							}

							.del {
								display: flex;
								justify-content: space-between;
								align-items: center;
							}

							.input-number {
								display: flex;
								justify-content: space-between;
								align-items: center;
								width: 90px;
								height: 30px;
								background: #F2F2F2;
								padding: 0px 5px;

								div {
									font-size: 16px;
									font-family: PingFangSC-Regular, PingFang SC;
									font-weight: 400;
									color: #000000;
									line-height: 22px;

								}

								.font {
									font-size: 19px;
									color: #000000;
								}
							}
						}
					}
				}

			}
		}

		.after {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-top: 10px;

			.button {
				padding: 7px 15px;
				text-align: center;
				border: 1px solid #CCCCCC;
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 22px;
				margin-left: 16px;
				cursor: pointer;
			}

			.blck {
				background: #000000;
				color: #FFFFFF;
			}
		}
	}

	.pagination {
		background-color: #FFFFFF;
		border-top: 1px solid #CCCCCC;
		margin-top: 20px;

		.pages {

			padding-top: 40px;
			margin: 0 auto;
			display: flex;
			justify-content: flex-end;

			.dis {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 20px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				width: 200px;
			}
		}
	}

	.dialog {
		text-align: center;
	}

	.dialog-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid #ECECEC;

		div {
			width: 224px;
			padding: 24px 0px;
			padding-bottom: 4px;

			cursor: pointer;
		}

		div:first-child {
			// font-size: 18px;
			// font-family: PingFangSC-Regular, PingFang SC;
			// font-weight: 400;
			// color: #777777;
		}
	}

	.pay {
		text-align: center;

		.title {
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #777777;
			line-height: 22px;
			padding: 15px 0px;
			text-align: center;
		}

		.total {
			font-size: 36px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 50px;
			text-align: center;
			padding: 15px 0px;
		}

		.type {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 60px 0px;
			margin-top: 0px;

			.wx,
			.alipay {
				text-align: center;

				div:first-child {
					border: 1px solid #777777;
					padding: 5px;

					img {
						width: 228px;
						height: 228px;
					}
				}

				div:last-child {
					margin-top: 17px;
					text-align: center;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #000000;

					img {
						width: 24px;
						height: 24px;
						vertical-align: middle;
						margin-right: 5px;
					}
				}
			}

			.wx {
				// margin-right: 70px;
			}
		}

	}

	.fabumainboxpay {
		text-align: center;

		.fabumainboxpay-titlef {
			.fabumainboxpay-t {
				font-size: 17px;
				font-weight: 400;
				color: #333333;
			}

			.fabumainboxpay-p {
				font-size: 22px;
				font-weight: 500;
				color: #EF0000;
				margin-top: 10px;
			}
		}

		.fabumainboxpay-pay {
			border-top: 1px solid #EEEEEE;
			margin-top: 20px;
			padding-top: 31px;

			.fabumainboxpay-pay-line {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 27px;

				.wximg {
					width: 27px;
					height: 27px;
					margin-right: 27px;
				}

				div {
					font-size: 13px;
					font-weight: 400;
					color: #333333;

					span {
						font-size: 12px;
						color: #777777;
						margin-left: 13px;
					}
				}

				.chooseimg {
					width: 20px;
					height: 20px;
				}
			}
		}

	}

	.dialog-footer-ggpay {
		.el-button {
			width: 40%;
			padding: 15px;
		}
	}

	.dialog-footer-gg1 {
		.btnbox {
			width: 160px;
			margin: 0 10px;
			height: 45px;
			line-height: 45px;
		}

		.cancelbtn {
			background: #F4F4F4;
			color: #4C4A48;
		}
	}
</style>